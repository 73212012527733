import { graphql, useStaticQuery } from "gatsby";

export default () => useStaticQuery(graphql`{
    bas: file(relativePath: { eq: "bas.jpg" }) {
        childImageSharp {
            fluid(maxHeight: 300) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
        }
    }
    jeroen: file(relativePath: { eq: "jeroen.jpg" }) {
        childImageSharp {
            fluid(maxHeight: 300) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
        }
    }
}`);

