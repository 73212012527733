import React from "react";
import Image from "gatsby-image";

export default props => (
    <section className="about">
        <div id="about" className="anchor"></div>
        <div className="container">
            <div className="text">
                <h2>About us</h2>
                <p>
                    We differ in musical background, taste and skill but we share a passion for enhancing experiences with custom music and sound.
                    As music technologists we love to record real instruments, play with synthesizers and blend different sounds and musical styles.
                </p>
                <p>
                    Above all, we enjoy a professional and fun collaboration in which our passion makes a project unforgettable!
                </p>
                <p>
                    Bas van Win · Jeroen Jaspers
                </p>
            </div>
            <div className="avatars">
                <div className="avatars__person">
                    <Image className="avatars__avatar" fluid={ props.avatars.bas.childImageSharp.fluid } />
                    <p className="avatars__name">Bas van Win</p>
                </div>
                <div className="avatars__person">
                    <Image className="avatars__avatar" fluid={ props.avatars.jeroen.childImageSharp.fluid } />
                    <p className="avatars__name">Jeroen Jaspers</p>
                </div>
            </div>
        </div>
    </section>
);

